import {types} from "./W29F2025_actions";

const initialState = {
    getLoadWorkDateType: [],
    getListOfShift: [],
    getListShiftRotation: [],

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W29F2025_LIST_OF_SHIFT_SUCCESS:
            return {
                ...state,
                getListOfShift: action.data
            };
        case types.W29F2025_LIST_SHIFT_ROTATION_SUCCESS:
            return {
                ...state,
                getListShiftRotation: action.data
            };
        default:
            return state;
    }
}