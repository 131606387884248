import { types } from "./W29F2012_actions";

const initialState = {
    getForm: [],
    getCboOtType: [],
    getRequiredFields: [],
    getCaption: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.GET_CBO_OTT_TYPE_SUCCESS:
            return {
                ...state,
                getCboOtType: action.data
            };
        case types.GET_FORM_SUCCESS:
            return {
                ...state,
                getForm: action.data
            };
        case types.W29F2012_GET_REQUIRED_FIELD_SUCCESS:
            return {
                ...state,
                getRequiredFields: action.data
            };
        case types.W29F2012_GET_CAPTION_SUCCESS:
            return {
                ...state,
                getCaption: action.data
            };
        default:
            return state;
    }
}