export const types = {
    GET_CBO_OTT_TYPE: "GET_CBO_OTT_TYPE",
    GET_CBO_OTT_TYPE_SUCCESS: "GET_CBO_OTT_TYPE_SUCCESS",
    GET_FORM:"GET_FORM",
    GET_FORM_SUCCESS: "GET_FORM_SUCCESS",
    W29F2012_CANCEL_APPROVAL: "W29F2012_CANCEL_APPROVAL",
    W29F2012_APPROVAL_STATUS: "W29F2012_APPROVAL_STATUS",
    W29F2012_GET_REQUIRED_FIELD: "W29F2012_GET_REQUIRED_FIELD",
    W29F2012_GET_REQUIRED_FIELD_SUCCESS: "W29F2012_GET_REQUIRED_FIELD_SUCCESS",
    W29F2012_GET_CAPTION: "W29F2012_GET_CAPTION",
    W29F2012_GET_CAPTION_SUCCESS: "W29F2012_GET_CAPTION_SUCCESS",
    W29F2012_CHECK_OTHOUR: "W29F2012_CHECK_OTHOUR",
};

export function getCboOtType(params, cb) {
    return {
        type: types.GET_CBO_OTT_TYPE,
        params,
        cb,
    };
}

export function getForm(params, cb) {
    return {
        type: types.GET_FORM,
        params,
        cb,
    };
}

export function approvalStatus(params, cb) {
    return{
        type: types.W29F2012_APPROVAL_STATUS,
        params,
        cb
    }
}

export function cancelApproval(params, cb) {
    return{
        type: types.W29F2012_CANCEL_APPROVAL,
        params,
        cb
    }
}

export function getRequiredFields(cb) {
    return {
        type: types.W29F2012_GET_REQUIRED_FIELD,
        cb,
    };
}

export function getCaption(cb) {
    return {
        type: types.W29F2012_GET_CAPTION,
        cb,
    };
}


export function checkOTHour(params, cb) {
    return {
        type: types.W29F2012_CHECK_OTHOUR,
        params,
        cb,
    };
}

