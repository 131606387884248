export const types = {
    W29F2030_LIST_OF_SHIFT: "W29F2030_LIST_OF_SHIFT",
    W29F2030_LIST_OF_SHIFT_SUCCESS: "W29F2030_LIST_OF_SHIFT_SUCCESS",
    W29F2030_GET_DATA_GRID: "W29F2030_GET_DATA_GRID",
    W29F2030_GET_DATA_FORM_POPUP: "W29F2030_GET_DATA_FORM_POPUP",
    W29F2030_SAVE: "W29F2030_SAVE",
    W29F2030_DELETE:"W29F2030_DELETE",

};

export function getListOfShift(cb) {
    return{
        type: types.W29F2030_LIST_OF_SHIFT,
        cb
    }
}

export function getDataGrid(params,cb) {
    return{
        type: types.W29F2030_GET_DATA_GRID,
        params,
        cb
    }
}

export function getDataFormPopup(params,cb) {
    return{
        type: types.W29F2030_GET_DATA_FORM_POPUP,
        params,
        cb
    }
}

export function save(params,cb) {
    return{
        type: types.W29F2030_SAVE,
        params,
        cb
    }
}

export function deletes(params, cb) {
    return {
        type: types.W29F2030_DELETE,
        params,
        cb,
    };
};