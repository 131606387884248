export const types = {
    W29F2011_GET_GRID_DATA: 'W29F2011_GET_GRID_DATA',
    W29F2011_GET_GRID_DATA_SUCCESS: 'W29F2011_GET_GRID_DATA_SUCCESS',
    W29F2011_APPROVAL_STATUS: 'W29F2011_APPROVAL_STATUS',
    W29F2011_APPROVAL_STATUS_SUCCESS: 'W29F2011_APPROVAL_STATUS_SUCCESS',
    W29F2011_CANCEL_APPROVAL: 'W29F2011_CANCEL_APPROVAL',
    W29F2011_CANCEL_APPROVAL_SUCCESS: 'W29F2011_CANCEL_APPROVAL_SUCCESS',
    W29F2011_GET_OT_TYPE_NAME: 'W29F2011_GET_OT_TYPE_NAME',
    W29F2011_GET_OT_TYPE_NAME_SUCCESS: 'W29F2011_GET_OT_TYPE_NAME_SUCCESS',
    W29F2011_GET_GRID_SETTING: "W29F2011_GET_GRID_SETTING",
    W29F2011_GET_GRID_SETTING_SUCCESS: "W29F2011_GET_GRID_SETTING_SUCCESS",

};

export function getDataGrid(params,cb) {
    return{
        type: types.W29F2011_GET_GRID_DATA,
        params,
        cb
    }
}

export function approvalStatus(params, cb) {
    return{
        type: types.W29F2011_APPROVAL_STATUS,
        params,
        cb
    }
}

export function cancelApproval(params, cb) {
    return{
        type: types.W29F2011_CANCEL_APPROVAL,
        params,
        cb
    }
}

export function getOTTypeName(params, cb) {
    return {
        type: types.W29F2011_GET_OT_TYPE_NAME,
        params,
        cb
    }
}

export function getGridSetting(cb) {
    return {
        type: types.W29F2011_GET_GRID_SETTING,
        cb
    }
}

