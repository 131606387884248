export const types = {
    W29F2006_GET_CBO_HANDOVER: 'W29F2006_GET_CBO_HANDOVER',
    W29F2006_GET_CBO_SHIFTS: 'W29F2006_GET_CBO_SHIFTS',
    W29F2006_GET_CBO_SHIFTS_SUCCESS: 'W29F2006_GET_CBO_SHIFTS_SUCCESS',
    W29F2006_GET_COLUMNS: 'W29F2006_GET_COLUMNS',
    W29F2006_GET_COLUMNS_SUCCESS: 'W29F2006_GET_COLUMNS_SUCCESS',
    W29F2006_GET_CBO_COLUMNS: 'W29F2006_GET_CBO_COLUMNS',
    W29F2006_GET_CBO_COLUMNS_SUCCESS: 'W29F2006_GET_CBO_COLUMNS_SUCCESS',
    W29F2006_GET_MASTER: 'W29F2006_GET_MASTER',
    W29F2006_GET_MASTER_SUCCESS: 'W29F2006_GET_MASTER_SUCCESS',
    W29F2006_GET_GRID_MASTER: 'W29F2006_GET_GRID_MASTER',
    W29F2006_GET_GRID_MASTER_SUCCESS: 'W29F2006_GET_GRID_MASTER_SUCCESS',
    W29F2006_GET_SELECTED_EMPLOYEES: 'W29F2006_GET_SELECTED_EMPLOYEES',
    W29F2006_GET_HOUR_DECIMAL: 'W29F2006_GET_HOUR_DECIMAL',
    W29F2006_GET_HOUR_DECIMAL_SUCCESS: 'W29F2006_GET_HOUR_DECIMAL_SUCCESS',
    W29F2006_SAVE_ADD_ATTENDANCE: 'W29F2006_SAVE_ADD_ATTENDANCE',
    W29F2006_SAVE_EDIT_ATTENDANCE: 'W29F2006_SAVE_EDIT_ATTENDANCE',
    W29F2006_COMMIT_AND_SAVE: 'W29F2006_COMMIT_AND_SAVE',
    W29F2006_GET_COEFFICIENT_COLUMN: 'W29F2006_GET_COEFFICIENT_COLUMN',
    W29F2006_GET_COEFFICIENT_COLUMN_SUCCESS: 'W29F2006_GET_COEFFICIENT_COLUMN_SUCCESS',
    W29F2006_GET_TOTAL_HOURS: 'W29F2006_GET_TOTAL_HOURS',
    W29F2006_GET_STATUS_EXITS_EMPLOYEE: 'W29F2006_GET_STATUS_EXITS_EMPLOYEE',
};

export function getColumns(params,cb) {
    return{
        type: types.W29F2006_GET_COLUMNS,
        params,
        cb
    }
}

export function getCoefficientColumn(params,cb) {
    return{
        type: types.W29F2006_GET_COEFFICIENT_COLUMN,
        params,
        cb
    }
}

export function getCboColumns(params,cb) {
    return{
        type: types.W29F2006_GET_CBO_COLUMNS,
        params,
        cb
    }
}

export function getCboHandOver(params,cb) {
    return{
        type: types.W29F2006_GET_CBO_HANDOVER,
        params,
        cb
    }
}

export function getCboShifts(params,cb) {
    return{
        type: types.W29F2006_GET_CBO_SHIFTS,
        params,
        cb
    }
}

export function getGetMaster(params,cb) {
    return{
        type: types.W29F2006_GET_MASTER,
        params,
        cb
    }
}

export function getGridMaster(params,cb) {
    return{
        type: types.W29F2006_GET_GRID_MASTER,
        params,
        cb
    }
}

export function getSelectedEmployees(params,cb) {
    return{
        type: types.W29F2006_GET_SELECTED_EMPLOYEES,
        params,
        cb
    }
}

export function getHourDecimal(cb) {
    return{
        type: types.W29F2006_GET_HOUR_DECIMAL,
        cb
    }
}

export function saveAddAttendance(params,cb) {
    return{
        type: types.W29F2006_SAVE_ADD_ATTENDANCE,
        params,
        cb
    }
}

export function saveEditAttendance(params,cb) {
    return{
        type: types.W29F2006_SAVE_EDIT_ATTENDANCE,
        params,
        cb
    }
}

export function commitAndSave(params,cb) {
    return{
        type: types.W29F2006_COMMIT_AND_SAVE,
        params,
        cb
    }
}

export function getTotalHours(cb) {
    return{
        type: types.W29F2006_GET_TOTAL_HOURS,
        cb
    }
}

export function getStatusExitsEmployee(params,cb) {
    return{
        type: types.W29F2006_GET_STATUS_EXITS_EMPLOYEE,
        params,
        cb
    }
}
