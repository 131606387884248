import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2010_actions";
import { delay } from "redux-saga";

export default function W29F2010Sagas() {
    return [watchGetFormGrid(), watchDeleteDataGird(),watchDeleteActOTHour(), watchLoadCheckUseOTType()];
}

export function* getFormGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2010/load-form-grid", data.params);
        if (response && response.data) {
            yield put({ type: types.GET_FORM_GRID_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form grid error ");
    }
}

export function* watchGetFormGrid() {
    while (true) {
        const watcher = yield takeLatest(types.GET_FORM_GRID, getFormGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//save confirm..
export function* deleteDataGird(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2010/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete data grid error");
    }
}

export function* watchDeleteDataGird() {
    while (true) {
        const watcher = yield takeLatest(types.DELETE_DATA, deleteDataGird);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadCheckUseOTType(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w29f2010/check-use-ottype");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load check use ot type error:", e);
    }
}

export function* watchLoadCheckUseOTType() {
    while (true) {
        const watcher = yield takeLatest(types.CHECK_USE_OT_TYPE, loadCheckUseOTType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* deleteActOTHour(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2010/delete-overtime-registration", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete data grid error");
    }
}

export function* watchDeleteActOTHour() {
    while (true) {
        const watcher = yield takeLatest(types.DELETE_ACT_OT_HOUR, deleteActOTHour);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
