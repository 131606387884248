import {types} from "./W29F2030_actions";

const initialState = {
    getListOfShift: [],

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W29F2030_LIST_OF_SHIFT_SUCCESS:
            return {
                ...state,
                getListOfShift: action.data
            };
        default:
            return state;
    }
}
