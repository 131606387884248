/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 8/7/2021
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import { types } from './W29F2017_actions';
import { delay } from 'redux-saga';

export default function W29F2017Sagas() {
    return [watchGetData(), watchSave()];
}

export function* getData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2017/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get data error');
    }
}

export function* watchGetData() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2017_GET_DATA, getData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2017/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save error');
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2017_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
