/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../W2X/W29F2006/W29F2006_actions";
import { delay } from "redux-saga";

export default function W29F2006Sagas() {
    return [
        watchGetColumns(),
        watchGetColumnsCaption(),
        watchGetCboColumns(),
        watchGetCboHandOver(),
        watchGetCboShifts(),
        watchGetMaster(),
        watchGetGridMaster(),
        watchGetSelectedEmployees(),
        watchSaveAddAttendance(),
        watchSaveEditAttendance(),
        watchGetHourDecimal(),
        watchCommitAndSave(),
        watchGetTotalHours(),
        watchGetStatusExitsEmployee(),
    ];
}

//get columns..
export function* getColumns(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/W29F2006/get-combo-type', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2006_GET_COLUMNS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get columns error');
    }

}

export function* watchGetColumns() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_COLUMNS, getColumns);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCoefficientColumn(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2006/load-column-caption', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2006_GET_COEFFICIENT_COLUMN_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get columns error');
    }

}

export function* watchGetColumnsCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_COEFFICIENT_COLUMN, getCoefficientColumn);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo columns..
export function* getCboColumns(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2006/load-acode', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2006_GET_CBO_COLUMNS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo columns error');
    }

}

export function* watchGetCboColumns() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_CBO_COLUMNS, getCboColumns);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo hand over..
export function* getCboHandOver(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2006/get-handover', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo handover error');
    }

}

export function* watchGetCboHandOver() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_CBO_HANDOVER, getCboHandOver);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo shifts..
export function* getCboShifts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2006/get-combo-shift', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo shifts error');
    }

}

export function* watchGetCboShifts() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_CBO_SHIFTS, getCboShifts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get master..
export function* getMaster(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W29F2006/get-master', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2006_GET_MASTER_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get master error');
    }

}

export function* watchGetMaster() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_MASTER, getMaster);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get grid master..
export function* getGridMaster(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W29F2006/get-detail', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2006_GET_GRID_MASTER_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid master error');
    }

}

export function* watchGetGridMaster() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_GRID_MASTER, getGridMaster);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get selected employees..
export function* getSelectedEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W29F2006/load-store-w29p2009', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get selected employees error');
    }

}

export function* watchGetSelectedEmployees() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_SELECTED_EMPLOYEES, getSelectedEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get hour decimal..
export function* getHourDecimal(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/W29F2006/get-hour-decimal');
        yield put({ type: types.W29F2006_GET_HOUR_DECIMAL_SUCCESS, data: response.data });
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get hour decimal error');
    }

}

export function* watchGetHourDecimal() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_HOUR_DECIMAL, getHourDecimal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save add attendance..
export function* saveAddAttendance(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2006/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save add attendance error');
    }

}

export function* watchSaveAddAttendance() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_SAVE_ADD_ATTENDANCE, saveAddAttendance);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save edit attendance..
export function* saveEditAttendance(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2006/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save add attendance error');
    }

}

export function* watchSaveEditAttendance() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_SAVE_EDIT_ATTENDANCE, saveEditAttendance);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//commit to check save 
export function* commitAndSave(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2006/commit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save check commit error');
    }

}

export function* watchCommitAndSave() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_COMMIT_AND_SAVE, commitAndSave);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get total hours
export function* getTotalHours(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2006/get-total-hours');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get total hours error');
    }
}

export function* watchGetTotalHours() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_TOTAL_HOURS, getTotalHours);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getStatusExitsEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2005/check', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get status exits employee error');
    }
}

export function* watchGetStatusExitsEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2006_GET_STATUS_EXITS_EMPLOYEE, getStatusExitsEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}