/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 2/25/2021
 * @Example 
 */
import Api                               from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types }                         from "./W29F2016_actions";
import { delay }                         from "redux-saga";

export default function W29F2012Sagas() {
    return [watchGetForm(), watchGetUseOtType(), watchApproval(),watchConfirmApproval(), watchGetGridSetting()];
}

export function* getUseOtType(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w29f2016/is-use-ottype");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get Ott Type error");
    }
}

export function* watchGetUseOtType() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2016_GET_USE_OTTYPE, getUseOtType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2016/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2016_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* approval(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w29f2016/approval", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get approval error");
    }
}

export function* watchApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2016_APPROVAL, approval);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* confirmApproval(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2016/confirm-approval", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get approval error");
    }
}

export function* watchConfirmApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2016_CONFIRM_APPROVAL, confirmApproval);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get grid setting
export function* getGridSetting(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2072/load-grid-setting');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid setting error');
    }
}

export function* watchGetGridSetting() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2016_GET_GRID_SETTING, getGridSetting);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

