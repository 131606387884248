export const types = {
    W29F2025_LIST_OF_SHIFT: "W29F2025_LIST_OF_SHIFT",
    W29F2025_LIST_OF_SHIFT_SUCCESS: "W29F2025_LIST_OF_SHIFT_SUCCESS",
    W29F2025_LOAD_FORM_GRID: "W29F2025_LOAD_FORM_GRID",
    W29F2025_LOAD_FORM_GRID_SUCCESS: "W29F2025_LOAD_FORM_GRID_SUCCESS",
    W29F2025_SAVE: "W29F2025_SAVE",
    W29F2025_LIST_SHIFT_ROTATION: "W29F2025_LIST_SHIFT_ROTATION",
    W29F2025_LIST_SHIFT_ROTATION_SUCCESS: "W29F2025_LIST_SHIFT_ROTATION_SUCCESS",
    W29F2025_LOAD_DROP_DOWN: "W29F2025_LOAD_DROP_DOWN",
};

export function getListOfShift(params,cb) {
    return{
        type: types.W29F2025_LIST_OF_SHIFT,
        params,
        cb
    }
}

export function getLoadFormGrid(params,cb) {
    return{
        type: types.W29F2025_LOAD_FORM_GRID,
        params,
        cb
    }
}

export function save(params,cb) {
    return{
        type: types.W29F2025_SAVE,
        params,
        cb
    }
}

export function getListShiftRotation(params,cb) {
    return{
        type: types.W29F2025_LIST_SHIFT_ROTATION,
        params,
        cb
    }
}

export function getLoadDropDown(cb) {
    return{
        type: types.W29F2025_LOAD_DROP_DOWN,
        cb
    }
}