import { types } from "./W29F2010_actions";

const initialState = {
    getFormGrid: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.GET_FORM_GRID_SUCCESS :
            return {
                ...state,
                getFormGrid: action.data
            };
        default:
            return state;
    }
}