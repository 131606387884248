import {types} from "./W29F2006_actions";

const initialState = {
    getColumns: [],
    getCboColumns: [],
    getMaster: null,
    getGridMaster: [],
    getCoefficientColumn: [],
    getHourDecimal: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W29F2006_GET_COLUMNS_SUCCESS:
            return {
                ...state,
                getColumns: action.data
            };
        case types.W29F2006_GET_CBO_COLUMNS_SUCCESS:
            return {
                ...state,
                getCboColumns: action.data
            };
        case types.W29F2006_GET_HOUR_DECIMAL_SUCCESS:
            return {
                ...state,
                getHourDecimal: action.data
            };
        case types.W29F2006_GET_MASTER_SUCCESS:
            return {
                ...state,
                getMaster: action.data
            };
        case types.W29F2006_GET_GRID_MASTER_SUCCESS:
            return {
                ...state,
                getGridMaster: action.data
            };
        case types.W29F2006_GET_COEFFICIENT_COLUMN_SUCCESS:
            return {
                ...state,
                getCoefficientColumn: action.data
            };
        default:
            return state;
    }
}