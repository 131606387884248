/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/1/2020
 * @Example 
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../W29F2025/W29F2025_actions";
import { delay } from "redux-saga";

export default function W29F2025Sagas() {
    return [
        watchGetListOfShift(),
        watchGetLoadFormGrid(),
        watchSave(),
        watchGetListShiftRotation(),
        watchGetLoadDropDown(),

    ];
}


//get list of shift
export function* getListOfShift(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2025/list-of-shift', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2025_LIST_OF_SHIFT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list of shift error',e);
    }

}

export function* watchGetListOfShift() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2025_LIST_OF_SHIFT, getListOfShift);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get load form grid
export function* getLoadFormGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2025/load-form-grid', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get load form error',e);
    }

}

export function* watchGetLoadFormGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2025_LOAD_FORM_GRID, getLoadFormGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

///save
export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2025/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save data error',e);
    }

}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2025_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get list shift rotation
export function* getListShiftRotation(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2025/list-shift-rotation', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2025_LIST_SHIFT_ROTATION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list shift rotation error',e);
    }

}

export function* watchGetListShiftRotation() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2025_LIST_SHIFT_ROTATION, getListShiftRotation);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get list dropddown
export function* getLoadDropDown(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2025/load-drop-down');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get DropDown error',e);
    }

}

export function* watchGetLoadDropDown() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2025_LOAD_DROP_DOWN, getLoadDropDown);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

