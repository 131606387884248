/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 6/30/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2012_actions";
import { delay } from "redux-saga";

export default function W29F2012Sagas() {
    return [
        watchGetForm(),
        watchGetCboOtType(),
        watchApprovalStatus(),
        watchCancelApproval(),
        watchGetRequiredFields(),
        watchGetCaption(),
        watchCheckOTHour(),
    ];
}

export function* getCboOtType(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2012/load-ottype", data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_OTT_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get Ott Type error");
    }
}

export function* watchGetCboOtType() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_OTT_TYPE, getCboOtType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2012/load-form", data.params);
        if (response && response.data) {
            yield put({ type: types.GET_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


//get approval status..
export function* approvalStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2011/approval', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('approval Status error');
    }

}

export function* watchApprovalStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2012_APPROVAL_STATUS, approvalStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cancel approval..
export function* cancelApproval(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2011/cancel-approval', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response.data, null)
        }
    }
    catch (e) {
        console.log('cancel Approval error');
    }

}

export function* watchCancelApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2012_CANCEL_APPROVAL, cancelApproval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get required fields..
export function* getRequiredFields(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2012/check');
        if (response && response.data) {
            yield put({type: types.W29F2012_GET_REQUIRED_FIELD_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response.data, null)
        }
    }
    catch (e) {
        console.log('get required field error');
    }

}

export function* watchGetRequiredFields() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2012_GET_REQUIRED_FIELD, getRequiredFields);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2056/load-caption');
        if (response && response.data) {
            yield put({type: types.W29F2012_GET_CAPTION_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get caption error');
    }

}

export function* watchGetCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2012_GET_CAPTION, getCaption);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* checkOTHour(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2012/check-othour', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get caption error');
    }

}

export function* watchCheckOTHour() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2012_CHECK_OTHOUR, checkOTHour);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
